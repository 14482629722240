@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text:wght@100;200;300;400;500&display=swap);
:root {
    /* --modal-background: rgb(255, 255, 255); */
    --modal-background: red;
    --modal-color: #00e673;
    --modal-load: #00e673;
    --modal-load-transparent: rgba(0, 230, 115, 0);
  }



  .modal--avaliacao-app-container .modal-content {
      background: red;
      background: var(--modal-background);
      box-shadow: 0px 0px 32px rgb(0 0 0 / 25%);
  }
  .modal--avaliacao-app-container .modal-body {
      padding-top: 0 !important;
  }
  #sak--popupchat {
      overflow-x: hidden;
      padding-top: 20px !important;
  }
  /* .sak--modal-sm {
    max-width: 600px;
    background: var(--modal-background);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 32px;
  } */
  .sak--modal-sm hr {
    border-color: rgb(218 217 217 / 30%);
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
      opacity: 0.07;
  }
  .sak--modal-sm.large {
    max-width: 1170px;
  }
  .sak--modal-sm > div > button {
    position: absolute;
    right: -15px;
    top: -15px;
    background: transparent;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
    transition: all 0.2s;
    cursor: pointer;
    z-index: 9999;
  }
  .sak--modal-sm > div > button:hover {
    background: #e1e4ef;
  }
  .sak--modal-sm > div > button i {
    color: #171b37;
  }
  .sak--modal-sm h5 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #828bab;
  }
  .sak--modal-sm h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #171b37;
  }
  .sak--loading-ponto {
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #00e673;
    background-color: var(--modal-load);
    color: #00e673;
    color: var(--modal-load);
    box-shadow: 9999px 0 0 0 #00e673;
    box-shadow: 9999px 0 0 0 var(--modal-load);
    -webkit-animation: sakLoadPonto 1s infinite linear;
            animation: sakLoadPonto 1s infinite linear;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .sak--loading-ponto::before,
  .sak--loading-ponto::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .sak--loading-ponto::before {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #00e673;
    background-color: var(--modal-load);
    color: #00e673;
    color: var(--modal-load);
    -webkit-animation: sakLoadPontoBefore 1s infinite linear;
            animation: sakLoadPontoBefore 1s infinite linear;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .sak--loading-ponto::after {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #00e673;
    background-color: var(--modal-load);
    color: #00e673;
    color: var(--modal-load);
    -webkit-animation: sakLoadPontoAfter 1s infinite linear;
            animation: sakLoadPontoAfter 1s infinite linear;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .sak--modal-sm #sak--popupchat {
    max-width: 1170px;
    height: calc(
      100vh - 200px
    ); /* Calcular altura de acordo ao seu projeto (base na logo do SAk) */
    max-height: calc(100vh - 200px);
    overflow-y: overlay;
    overflow-x: hidden;
    padding-top: 20px;
    padding-right: 20px;
  }
  .sak--modal-sm #sak--popupchat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(210 210 210 / 40%);
  }
  .sak--modal-sm #sak--popupchat::-webkit-scrollbar {
    width: 6px;
    background: rgb(185 185 185 / 10%);
  }
  #sak--popupchat {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  #sak--popupchat li {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    transition: all 1s;
    -webkit-animation: novaMensagem forwards 0.5s;
            animation: novaMensagem forwards 0.5s;
  }
  #sak--popupchat li + li {
    margin-top: 15px;
  }
  #sak--popupchat li[data-me] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto {
    background-color: white;
    color: white;
    box-shadow: 9999px 0 0 0 white;
    -webkit-animation: sakLoadPontoWhite 1s infinite linear;
            animation: sakLoadPontoWhite 1s infinite linear;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::before,
  #sak--popupchat li[data-me] .sak--loading-ponto::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::before {
    background-color: white;
    color: white;
    -webkit-animation: sakLoadPontoBeforeWhite 1s infinite linear;
            animation: sakLoadPontoBeforeWhite 1s infinite linear;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::after {
    background-color: white;
    color: white;
    -webkit-animation: sakLoadPontoAfterWhite 1s infinite linear;
            animation: sakLoadPontoAfterWhite 1s infinite linear;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  #sak--popupchat li > time {
    margin-left: 12px;
    font-size: 0.6rem;
    color: #2f334a;
  }
  #sak--popupchat li > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
  }
  #sak--popupchat li > span time {
    font-size: 0.6rem;
    margin-top: 6px;
    color: #2f334a;
  }
  #sak--popupchat li > span img {
    max-width: 50px;
    width: 100%;
    max-height: 50px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  #sak--popupchat li > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: #2f334a;
    font-weight: 400;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
    font-size: 0.9rem;
    margin-bottom: 18px;
    -webkit-animation: animatexto 0.5s ease-in-out forwards;
            animation: animatexto 0.5s ease-in-out forwards;
    box-shadow: 0 1px 8px 0 rgb(90 60 122 / 08%);
  }
  #sak--popupchat li > div strong {
    font-weight: 600;
    margin-left: 6px;
    margin-right: 6px;
  }
  #sak--popupchat li[data-me] > div {
    color: white;
    background: #00e673;
    background: var(--modal-color);
    border-radius: 0.75rem 0.75rem 0.125rem 0.75rem;
  }
  #sak--popupchat li > div video {
    max-width: 300px;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
  }
  #sak--popupchat li .popup--videos {
    padding: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
  }
  #sak--popupchat li .popup--buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: none;
  }
  #sak--popupchat li .popup--buttons span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  #sak--popupchat li .popup--buttons span a i {
    margin-right: 8px;
    font-size: 1.1rem;
    color: white;
    display: none;
  }
  #sak--popupchat li .popup--buttons span a {
    background: #00e673;
    background: var(--modal-color);
    padding: 16px 24px;
    color: white;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
  }
  #sak--popupchat li .popup--buttons span a:hover {
    background: #00e673;
    background: var(--modal-color);
    box-shadow: rgb(0 0 0 / 29%) 0px 6px 21px -11px,
      rgb(47 49 72 / 30%) 0px 13px 17px -16px;
  }
  #sak--popupchat li .popup--buttons span a + a {
    margin-left: 12px;
  }
  #sak--popupchat li .popup--buttons h5 {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.7rem;
    color: black;
  }
  @-webkit-keyframes animatexto {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animatexto {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes novaMensagem {
    from {
      -webkit-transform: translateX(-60px);
              transform: translateX(-60px);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes novaMensagem {
    from {
      -webkit-transform: translateX(-60px);
              transform: translateX(-60px);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes sakLoadPonto {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9999px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 #00e673;
      box-shadow: 9999px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9999px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPonto {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9999px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 #00e673;
      box-shadow: 9999px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9999px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @-webkit-keyframes sakLoadPontoBefore {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9984px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 #00e673;
      box-shadow: 9984px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9984px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPontoBefore {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9984px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 #00e673;
      box-shadow: 9984px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 9984px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @-webkit-keyframes sakLoadPontoAfter {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 10014px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 #00e673;
      box-shadow: 10014px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 10014px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPontoAfter {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 10014px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 #00e673;
      box-shadow: 10014px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(0, 230, 115, 0);
      box-shadow: 10014px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @-webkit-keyframes sakLoadPontoWhite {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 white;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes sakLoadPontoWhite {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 white;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @-webkit-keyframes sakLoadPontoBeforeWhite {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 white;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes sakLoadPontoBeforeWhite {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 white;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @-webkit-keyframes sakLoadPontoAfterWhite {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 white;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes sakLoadPontoAfterWhite {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 white;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }

.promo-banner {
    display: flex;
    width: 100%;
    min-height: 45px;
    /* //background-color: #1569ef; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main-background-toolbar-plus {
    background-color: #010202 !important;
}

.promo-banner-neon-wrapper {
    background-color: #01194a;
    height: 45px;
    /* overflow: hidden; */
}

#promo-banner-neon {
    width: 233px;
    -webkit-transform: translate(0px, 6px);
            transform: translate(0px, 6px);
    margin-left: -15px;
}

#promo-banner-neon .st0 {
    fill: none;
    stroke: #5ef8ff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

#promo-banner-neon .st1 {
    opacity: 0.2;
    fill: none;
    stroke: #5ef8ff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

#promo-banner-neon .st2 {
    fill: none;
    stroke: #5ef8ff;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0.7;
}

.promo-banner-cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.promo-banner-cta {
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}


.promo-banner-lifetime {
    background-color: #5ef8ff;
    padding: 1px 5px;
    border-radius: 3px;
}

.promo-banner-button {
    font-family: 'Roboto', sans-serif;
    background-color: #01194a;
    font-size: 15px;
    color: #5ef8ff;
    border-radius: 100px;
    padding: 3px 11px;
    font-weight: bold;
    margin-left: 10px;
    letter-spacing: 0.4px;
    box-shadow: 0px 4px 0px 0px #01194a5c;
    transition: text-shadow .3s ease-in-out, color .3s ease-in-out;
}

.promo-banner-button:hover {
    text-shadow: 0px 0px 8px;
    color: white;
}

.promo-banner-button a {
    text-decoration: none;
    color: #5ef8ff;
}

#promo-banner-tag {
    -webkit-transform: rotate(10deg) translate(-10px, -1px);
            transform: rotate(10deg) translate(-10px, -1px);
    height: 45px;
    overflow: visible;
    z-index: 1021;
}

#promo-banner-tag .st0 {
    opacity: 0.3;
}

#promo-banner-tag .st1 {
    fill: #01194a;
}

#promo-banner-tag .st2 {
    fill: #FFFFFF;
}

#promo-banner-tag .st3 {
    fill: none;
    stroke: #2338d9;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

#promo-banner-tag .st4 {
    fill: none;
    stroke: #2338d9;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.promo-banner-crevron {
    flex: 1 0;
    height: 45px;
    position: relative;
    overflow: hidden;
}

.promo-banner-crevron.-left {
    background-color: #01194a;
}

.promo-banner-crevron-svg {
    position: absolute;
    top: 50%;
}

.-left .promo-banner-crevron-svg {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
}

.-right .promo-banner-crevron-svg {
    -webkit-transform: translateY(-50%) scaleX(-1) scaleY(1);
            transform: translateY(-50%) scaleX(-1) scaleY(1);
    left: 0;
}


.promo-banner-crevron-svg .st0 {
    -webkit-filter: blur(0.8px);
            filter: blur(0.8px);
}

.promo-banner-crevron-svg .st1 {
    display: none;
}

.-left .promo-banner-crevron-svg .st0 {
    fill: #0d6efd;
}

.-left .promo-banner-crevron-svg .st1 {
    stroke: #0d6efd;
}

.promo-banner-crevron-svg .st1 {
    fill: none;
    stroke: #0d6efd;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    -webkit-filter: blur(3px);
            filter: blur(3px);
}

.-right .promo-banner-crevron-svg .st0 {
    fill: #5ef8ff;
}

.-right .promo-banner-crevron-svg .st1 {
    stroke: #5ef8ff;
}

.promo-banner-pointed-edge {
    margin: 0 10px 0 -22px;
    height: 45px;
}

.promo-banner-pointed-edge .st0 {
    fill: #01194a;
}

.promo-banner-crevron.-small {
    display: none;
}

@media (max-width: 1089px) {
    .promo-banner {
        flex-wrap: wrap;
    }

    .promo-banner-pointed-edge {
        display: none;
    }

    .promo-banner-crevron.-right {
        display: none;
    }

    .promo-banner-crevron.-small {
        display: block;
        background-color: #01194a;
    }

    .promo-banner-cta-wrapper {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
        flex-wrap: wrap;
    }

    .promo-banner-crevron,
    .promo-banner-neon-wrapper {
        z-index: 2;
    }

    .promo-banner-button,
    #promo-banner-tag {
        display: inline;
    }

    .-right .promo-banner-crevron-svg .st0 {
        fill: #0d6efd;
    }

    .-right .promo-banner-crevron-svg .st1 {
        stroke: #0d6efd;
    }
}

@media (max-width: 725px) {
    .promo-banner {
        display: none !important;
    }

    .promo-banner-cta-wrapper {
        padding: 10px;
    }

    #promo-banner-tag {
        height: 4px;
    }

    #promo-banner-tag .st4 {
        -webkit-transform: rotate(-30deg);
                transform: rotate(-30deg);
        -webkit-transform-origin: 14px 20px;
                transform-origin: 14px 20px;
    }

    .promo-banner-button,
    .promo-banner-cta {
        position: relative;
        z-index: 1;
    }

    .promo-banner-button {
        margin: 3px 0;
    }

}

@media (max-width: 520px) {
    .promo-banner-cta {
        font-size: 3.5vw;
        margin-bottom: 8px;
    }
}

@media (max-width: 320px) {

    .promo-banner-crevron,
    .promo-banner-neon-wrapper {
        height: 14vw;
    }

    #promo-banner-neon {
        max-width: 80vw;
        -webkit-transform: translate(0px, 1.6vw);
                transform: translate(0px, 1.6vw);
    }

}

#promo-banner-neon .st1 {
    opacity: 0 !important;
}




#container--neon {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;
    gap: 4rem;
}

/*Neon*/
#container--neon a {
    text-align: center;
    font-size: 7em;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1;
    text-decoration: none;
    color: #5ef8ff;
}

#container--neon a:nth-child(1) {
    font-family: 'Big Shoulders Inline Text', cursive;
    text-shadow: 0 0 calc(5px) #5ef8ff, 0 0 calc(10px) #5ef8ff, 0 0 calc(15px) #5ef8ff, 0 0 calc(20px) #5ef8ff,
        0 0 calc(35px) #5ef8ff, 0 0 calc(40px) #5ef8ff, 0 0 calc(50px) #5ef8ff, 0 0 calc(75px) #5ef8ff;
}

